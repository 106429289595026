<template>
    <!-- <h5 class="mb-0 px-4 py-3">Charts</h5>
        <v-divider class="my-0"></v-divider> -->
  
    <v-row class="px-5 product-row">
 
      <!-- Supplier -->
      <!-- <v-col md="12" class="pt-1">
          <p class="mb-0 h4 font-weight-bold">Supplier</p>
        </v-col> -->
      <!-- <v-col md="12"  >
          <v-simple-table class="bt-table" style="border: 2px solid #f5f5f5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center th" width="200">Supplier</th>
                  <th class="text-center th" width="50">Price</th>
                  <th class="text-center th" width="50">UOM Measure</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center th">Nestlé Global. ()</td>
                  <td class="text-center th">0.00</td>
                  <td class="text-center th">CAN</td>
                </tr>
                <tr style="background-color: #f5f5f5">
                  <td class="text-center th">Nestlé Global. ()</td>
                  <td class="text-center th">12.00</td>
                  <td class="text-center th">CTN</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col> -->
      <v-col md="12" class="" v-if="salary?.logs?.length">
        <v-simple-table
          fixed-header
          style="height: calc(100vh - 280px)"
          class="bt-table product-simple-table"
        >
          <template v-slot:default>
            <!-- table titles -->
            <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
              <tr>
                <th
                  v-for="(supplier_h, index) in supplierTableHeaders"
                  :key="'$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading"
                  :style="{ maxWidth: `${supplier_h.width}px` }"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex align-center" v-on="on" v-bind="attrs">
                        <template v-if="supplier_h.heading === 'primary_sales_price'">
                          <v-icon color="blue">mdi-circle</v-icon>
                        </template>
                        <template v-if="supplier_h.heading === 'secondary_purchase_price'">
                          <v-icon color="red">mdi-circle</v-icon>
                        </template>
                        <p class="text-truncate mb-0">{{ supplier_h.title }}</p>
                      </div>
                    </template>
                    <span class="h5 text-center">{{ supplier_h.title }}</span>
                  </v-tooltip>
                </th>
              </tr>
            </thead>
  
            <tbody v-if="!pageLoading">
              <template v-if="mod_Suppliers.length">
                <tr
                  :class="index % 1 === 0 && '#fff'"
                  v-for="(row, index) in salary?.logs"
                  class="product-listing-tr"
                  :key="'k_' + index"
                >
                  <td
                    style="border: 2px solid #f4f4f4;"
                    :width="data_headings.width"
                    v-for="(data_headings, idx) in supplierTableHeaders"
                    :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                    class="px-2"
                  >
                    <div
                      class="d-flex justify-center align-center"
                      v-if="data_headings.heading === 'supplier_status'"
                    >
                      <v-switch
                        color="green"
                        :value="false"
                        :ripple="false"
                        hide-details
                        inset
                        disabled="true"
                        class="mt-0"
                        v-model="row[data_headings.heading]"
                      ></v-switch>
                    </div>
                    <div
                      class="d-flex justify-center align-center"
                      v-else-if="
                        data_headings.heading === 'primary_sales_price' ||
                        data_headings.heading === 'secondary_purchase_price'
                      "
                    >
                      {{ formatMoneyVal(row[data_headings.heading]) }}
                    </div>
                    <div
                      class="mb-0"
                      v-else-if="data_headings.heading === 'added_at'"
                    >
                      {{ formatedateTimes(row[data_headings.heading]) }}
                    </div>
                    <p v-else class="mb-0 h5">
                      {{ row[data_headings.heading] }}
                      <span v-if="row[data_headings.title.toLowerCase() + '_uom']">
                        / {{ row[data_headings.title.toLowerCase() + "_uom"] }}
                      </span>
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col v-else md="12" class="text-center mt-4">
        <p class="m-0 text-center black--text text-h6">
          <img
            width="30"
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mr-4"
          />
          Uhh... There are no logs available at the moment.
        </p>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import { formatMoney } from "accounting-js";
  import moment from "moment-timezone";
  import { GET_SALARY } from "@/core/lib/customer.lib";

  export default {
    name: "product-chart",
    title: "Chart Product",
   
   
    data() {
      return {
        salary:[],
        supplierTableHeaders: [
          { title: "Description", heading: "description", width: 480 },
  
          { title: "Created Time", heading: "added_at", width: 150 },
        ],
        product: {
          suppliers: [
            {
              id: 1,
              supplier_name: "Nestlé Global. ()",
              supplier_part: "Part",
              supplier_status: "Active",
              primary_sales_price: "48.00",
              secondary_purchase_price: "30.00",
              primary_uom: "CAN",
              secondary_uom: "CTN",
            },
          ],
        },
      };
    },
    methods: {
        async getSalary() {
        this.salaryId = this.$route.params.id;
  
        const salary = await GET_SALARY(this.salaryId);
        this.salary = salary;
        this.salary.salary_month = Number(salary.month)
        this.salary.salary_year = Number(salary.year)
        this.singleemployee = salary.employee;
        let selMonth = this.salary.salary_month;
        let monthNumber = String(selMonth).padStart(2, "0"); // Ensure two-digit format
        this.monthName = moment(monthNumber, "MM").format("MMMM"); // Get full month name
 
        
      },
      formatedateTimes(param) {
        return moment(param).format("DD/MM/YYYY hh:mm A");
      },
      formatMoneyVal(money) {
        return formatMoney(money, {
          symbol: "RM",
          decimal: ".",
          thousand: ",",
          precision: 2,
          format: "%s%v",
        });
      },
    },
    computed: {
      mod_Suppliers() {
        return this.product.suppliers;
      },
    },
    mounted() {
        this.getSalary();
      console.log("Router => ", this.$router);
    },
  };
  </script>
  
  <style scoped>
  .product-row {
    margin: 0 !important;
  }
  </style>
  