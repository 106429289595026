<template>
  <v-autocomplete
    clearable
    :multiple="multiple"
    :id="id"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :items="items"
    :rules="rules"
    :item-text="itemText"
    :item-value="itemValue"
    :hide-details="hideDetails"
    outlined
    class="mt-0 pt-0"
    v-model="autocompleteinput"
    :placeholder="placeholder"
    v-on:change="$emit('change', autocompleteinput)"
    v-on:click:clear="clearText"
    @blur="blur"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: "autocomplete-input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Object, Number, String],
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.autocompleteinput = value;
      },
    },
  },
  data() {
    return {
      autocompleteinput: null,
    };
  },
  methods: {
    clearText() {
      this.$emit("input", null);
      this.$emit("click:clear", true);
    },
    blur(evt) {
      console.log({ evt });
    },
  },
  mounted() {
    this.autocompleteinput = this.value;
  },
};
</script>
