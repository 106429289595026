<template>
  <v-sheet class="item" id="item" style="height: calc(100vh - 140px)">
    <v-row class="justify-space-between">
      <!-- {{data}} -->
      <!-- {{ this.$route.query.page }} -->
      <!-- <pre>{{ payment_items[0].parent_id.code }} {{ payment_items[0].parent_id.barcode }}</pre> -->
      <!-- <v-col md="12" class="header-title py-0"></v-col> -->
      <v-col md="6" class="" style="background-color: #f0f8ff">
      
      </v-col>

      <v-col md="6" class="text-right" style="background-color: #f0f8ff">
        <div class="d-flex align-center justify-content-end">
          <v-btn class="ml-2" :disabled="pageLoading" depressed color="primary" tile  link
                      v-on:click="routeToDetail($route.params.id)"> Overview </v-btn>
          <v-btn class="ml-2" :disabled="pageLoading" depressed tile @click="goBack">     <v-icon class="pa-1">mdi-arrow-left</v-icon> Back </v-btn>
        </div>
      </v-col>

      <!-- left side -->
      <v-col v-if="false" md="3" class="pr-2">
        <v-card elevation="2" style="height: 100%">
          <v-card-item class="d-flex justify-center pt-3">
            <ImageTemplate
              src="https://upload.wikimedia.org/wikipedia/commons/4/47/Hamburger_%28black_bg%29.jpg"
              :maxWidth="150"
              :maxHeight="150"
              :aspectRatio="1"
              :contain="false"
              circle
            />
          </v-card-item>

          <v-card-item class="d-flex justify-center align-center">
            <v-card-title class="font-weight-bold">bthrust pvt ltd</v-card-title>
          </v-card-item>

          <v-card-item class="d-flex align-center justify-center pb-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2 white--text"
                  color="blue darken-4"
                  :to="{
                    name: 'purchase-order-update',
                    params: { id: customerId },
                    query: { t: new Date().getTime() },
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2 white--text"
                  color="red lighten-1"
                  v-on:click="deleteCustomerConfirm()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-card-item>
          <v-divider class="mt-0"></v-divider>
          <v-card-item class="d-flex flex-column align-center" style="overflow-y: auto">
            <v-row class="basic-detail-row" style="width: 100%">
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Barcode</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">#PO0001</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Ref Number</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">101</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Delivery Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>08/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Created Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>06/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Created by</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">Supper Admin</p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Updated Date</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">
                  <v-icon size="18">mdi-calendar</v-icon>06/09/2023
                </p>
              </v-col>
              <v-col md="6" class="py-1 text-subtitle-1">
                <p class="text-truncate mb-0 font-weight-bold" dark>Updated by</p>
              </v-col>
              <v-col md="6" class="d-inline-block text-truncate py-1 text-subtitle-1">
                <p class="text-truncate mb-0" color="primary">Supper Admin</p>
              </v-col>
            </v-row>

            <div class="py-5">
              <v-btn
                :disabled="pageLoading"
                depressed
                tile
                class="mx-2 white--text"
                color="blue darken-4"
                :to="{
                  name: 'purchase-order-update',
                  params: { id: itemId },
                  query: { t: new Date().getTime() },
                }"
              >
                Edit
              </v-btn>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
      <v-col class="pt-0 pe-0" style="border-right: 1px solid #d8dbdd">
        <v-tabs
          v-model="purchaseOrderTab"
          background-color="transparent"
          color="blue darken-4"
          class="customtabs w-100"
        >
          <v-tab
            style="width: 100%"
            v-for="(tab, index) in product_tabs"
            :key="`product_${tab.key}_${index}`"
            :href="`#${tab.key}`"
            icons-and-text
          >
            <v-icon :size="18" class="me-1">{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-divider class="mt-0"></v-divider>
        <!-- <v-card>
          <v-card-item> -->
        <v-tabs-items v-model="purchaseOrderTab" style="height: 65vh; overflow-y: auto">
          <template v-for="(tab, index) in product_tabs">
            <v-tab-item :value="tab.key" :key="tab.key + '_' + index">
              <component
                :is="tab.template"
                :parentdetails="data"
                :pdetails="datas"
                :key="rerenderKey"
              />
            </v-tab-item>
          </template>
        </v-tabs-items>
        <!-- </v-card-item>
        </v-card> -->
      </v-col>

     
    </v-row>
    <DeleteTemplate
      type="Purchase Order"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
    <RecordPaymentDialog
      :dialog="recordPaymentDialog"
      :bulkdata="bulk"
      :pdetails="single"
      @close="recordPaymentDialog = false"
    ></RecordPaymentDialog>
  
  
  </v-sheet>
</template>

<script>
import { toSafeInteger, find, startsWith } from "lodash";
import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CHECK_PO } from "@/core/lib/pos.lib";
import { FIND_RESERVATION, GET_All_RESERVATION } from "@/core/lib/customer.lib";

import { formatMoney } from "accounting-js";
import moment from "moment-timezone";
// import { EventBus } from "@/core/event-bus/event.bus";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import { SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import DeleteTemplate from "@/view/components/DeleteTemplate";
// import PaymentsTab from "./PaymentsTab";
import { PATCH, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";

//import PendingDetailTab from "./PendingDetailTab";
// import AttactmentTab from "./AttactmentTab";
//  import PaymentsTab from "./PaymentsTab";
import SalaryLogTab from "./SalaryLogTab";
import StatusTab from "./StatusTab";

//  import ChatNotes from "./ChatNotes";

// import RecordPaymentDialog from "./RecordPaymentDialog";
// import { SET_PO } from "@/core/services/store/listing.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "item-detail",
  title: "Detail Item",
  data() {
    return {
      currentElement: 0,
      searchIteam: [],
      customerMoreAction: [
        {
          title: "Mark as Approve",
          icon: "mdi-check-all",
          action: "approve",
        },
      ],
      rerenderKey: Number(new Date()),
      recordPaymentDialog: false,
      pageLoading: true,
      totalAmount: [],
      dialog: false,
      url: "reservation-note",
      types: "reservation",

      deleteText: null,
      payment_items: [],
      text:null,
      formValid: false,
      deleteEndpoint: null,
      deleteDialog: false,
      images: [],
      customer_name:null,
      customer_number:null,
      checkedValues: [],
      checked: [],
      record: [],
      data: {},
      bulk: [],
      checks: 1,
      single: {},
      datas: {},
      datass: [],
      itemId: null,
      selectedItem: 0,
      checkbox: true,
      selectedRows: [],
      showFilter: false,
      delivery_date: "",
      searchSupplier: "",
      paymentType: null,
      bulkitems: [
        { title: "Record Primary Payment", icon: "mdi-check-all", action: "record" },
        { title: "Convert to Bill", icon: "mdi-check-all", action: "bill" },
        // { title: "Delete", icon: "mdi-check-all", action: "delete" },
      ],
      paymentTypeList: [
        {
          text: "Final Paid",
          value: "Paid",
          color: "",
        },
        {
          text: "Draft",
          value: "Draft",
          color: "",
        },
        {
          text: "Initial Paid",
          value: "Initial Paid",
          color: "",
        },
        {
          text: "Initial Pending",
          value: "Pending",
          color: "",
        },
        {
          text: "Final Pending",
          value: "final_pending",
          color: "",
        },
      ],
      supplierFilter: [
        {
          title: "Actions",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "#",
          status: true,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Details",
          status: false,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "blue darken-4",
          },
        },
        {
          title: "Supplier",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Contact Person",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
      ],
      items: [],
      purchaseOrderTab: null,
      product_tabs: [
        {
          key: "status",
          title: "Status",
          icon: "mdi-StatusTab",
          template: "StatusTab",
        },
        {
          key: "logs",
          title: "Logs",
          icon: "mdi-history",
          template: "SalaryLogTab",
        },
      ],
      item: {
        barcode: null,
        name_en: null,
        name_ch: null,
        payment_mode: null,
        reward: null,
        reward_point: null,
        sale: null,
        sale_amount: null,
        sale_start: null,
        sale_end: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        image: [],
        amount: null,
      },
      purchase_order_details: [
        {
          name: "Code",
          value: "BT002",
        },
        {
          name: "Category",
          value: "Disposable",
        },
        {
          name: "Department Category",
          value: "Bar Alcoholic",
        },
        {
          name: "Primary (sales) UOM",
          value: "BTL (BTL)",
        },
        {
          name: "Secondary (purchase) UOM",
          value: "CTN (CTN)",
        },
        {
          name: "Unit Description (primary sales uom)",
          value: "250ml",
        },
      ],
      purchaseOrder: [
        {
          id: 2,
          barcode: "PO0001",
          supplier: "bthrust pvt ltd",
          suppliers: null,
          refNumber: "101",
          delivery_date: "08/09/2023",
          project: "",
          amountValue: "$ 4,750.00",
          amountStatus: "Paid",
          po_status: "Draft",
          paymentModes: "Cash",
          payment_voucher: "ABCD123",
        },
      ],

      rowuct: {
        name: "Pineapple juice",
      },
    };
  },
  methods: {
    routeToDetail(id) {
      this.$router.push({
        name: "salaryDetail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    remarkPop(row) {
      this.text = row.message;
      this.id = row.id;
      this.customer_name = row.customer_name;
      this.customer_number = row.customer_number;

      console.log(row, "row");
      this.dialog = true;
    },
    goBackss() {
      this.$router.go(-1); // Go back to previous page
    },
    routeTo() {
      this.$router.push({
        name: "purchase-order",

        query: { t: new Date().getTime(), page: this.$route.query.page },
      });
    },
    downloadPdf() {
      this.pdf = this.$route.params.id;
      console.log(this.pdf, "pdf");
      let downloadURL = process.env.VUE_APP_API_URL + `purchase-order-pdf/${this.pdf}`;
      console.log(downloadURL, "downloadURL");
      downloadURL = new URL(downloadURL);
      window.open(downloadURL, "_blank");
    },
   
    approve(id) {
      this.$store
        .dispatch(PUT, {
          url: `purchase-order-approve`,
          data: {
            id: id,
          },
        })
        .then((data) => {
          console.log(data);
          this.$router.replace({
            name: "purchase-order",
            // params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Purchase Order Approved Successfully." },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    searchingIteam() {
      // let data = this.searchIteam.filter((item) =>
      //   startsWith(item.supplier.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
      // );
      let data = this.searchIteam.filter((item) => {
        return (
          startsWith(item.customer_name.toLowerCase(), this.searchSupplier.toLowerCase(), 0) ||
          startsWith(item.type.toLowerCase(), this.searchSupplier.toLowerCase(), 0)
        );
      });
      this.items = data;
      console.log(data, "data");
    },

    fill() {
      if (!this.paymentType) {
        this.$store.commit(SET_ERROR, [
          { model: true, message: "The payment type field is required" },
        ]);
        return false;
      }
      this.date = this.delivery_date;
      this.po_id = this.$route.params.id;
      this.status = this.paymentType;

      this.$store
        .dispatch(PATCH, {
          url: `purchase-order/supplier-filter`,
          data: {
            delivery_date: this.date,
            status: this.status,
            id: this.po_id,
          },
        })
        .then((data) => {
          this.items = data.data;
          this.showFilter = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    async ttsupplier(item) {
      // this.checkbox = this.checked;

      const pos = await CHECK_PO(item);
      this.checks = pos;
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "record":
          // updateMoreAction
          _this.recordPaymentDialog = true;
          break;
        case "bill":
          _this.$router.push({
            name: "bills-create",
            query: { t: new Date().getTime() },
          });

          break;
        case "delete":
          _this.bulkdeleteConfirm(this.bulk);

        // _this.$router.push({
        //   name: "bills-create",
        //   query: { t: new Date().getTime() },
        // });
      }
    },
    setCurrent(i) {
      this.currentElement = i;
    },
    // getTotal(){
    //       this.product = this.datass

    // 		let sum = 0;
    //     console.log(this.pdetails)
    // 		this.product?.forEach((element) => {

    // 			sum = sum + Number(element.total);
    // 		});
    //     console.log(sum)
    // 		this.total= Number(sum);
    //     return this.total;
    // 		//  this.getDiscount();

    //     },
    //     getTotals(){
    //       this.product = this.datas

    // 		let sum = 0;

    // 		this.product?.forEach((element) => {

    // 			sum = sum + Number(element.total);
    // 		});
    //     console.log(sum)
    // 		this.totals= Number(sum);
    //     return this.totals;
    // 		//  this.getDiscount();

    //     },

    // async getPayments(item) {
    //   this.purchase = item;

    //   const pos = await FIND_POCHECK(item);
    //   this.check = pos;
    //   console.log(this.check, 'this.check')
    //   this.totalAmount = this.check;

    //   let sum = 0;

    //   this.totalAmount?.forEach((element) => {
    //     sum = sum + Number(element.total);
    //   });

    //   this.totalPayment = Number(sum);
    //   console.log(this.totalPayment, 'totalPayment')
    //   this.bulk = pos;
    //   this.single = {};
    //   this.key = this.checked;
    //   this.ttsupplier(this.checked);
    //   this.$store.dispatch(SET_PO, this.key);

    //   EventBus.$emit("key", this.key);
    // },

    /*  async getPayments(item) {
      this.purchase = item;
      const pos = await FIND_POCHECK(item);
      this.datass = pos;
    }, */
    // async getPayment(item) {
    //   this.purchase = item;
    //   const po = await FIND_POS(this.purchase);
    //   this.item = {
    //     barcode: po?.barcode,
    //     uuid: po?.uuid,
    //     bill: po?.bill,
    //     name_ch: po?.name_ch,
    //     payment_twos: po?.payment_two,
    //     payment_mode: po?.payment_mode,

    //     supplier: po?.supplier?.company_name,
    //     delivery_date: po?.delivery_date,
    //     refNumber: po?.refNumber,
    //     receivingAdd: po?.receivingAdd,
    //     total: po?.total,
    //     status: po?.status,
    //     user_name: po?.added_by?.display_name,
    //     added_at: po?.added_at,
    //   };
    //   this.currentElement = this.item?.id;
    //   this.single = po;
    //   this.datas = po;
    //   this.data = po;

    // },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    deleteConfirm() {
      this.deleteText = this.item.barcode;
      this.deleteEndpoint = `purchase-order/${this.$route.params.id}`;
      this.deleteDialog = true;
    },
    bulkdeleteConfirm() {
      let deletBarcode = this.bulk.map((item) => item.barcode);

      this.deleteText = deletBarcode.join(", ");
      this.deleteEndpoint = `item/${this.itemId}`;
      this.deleteDialog = true;
    },
    pageTitle() {
      if (this.item.name_en) {
        return this.item.name_en;
      }
      return "Purchase Order";
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },

    async getItem() {
      try {
        const item = await FIND_RESERVATION(this.$route.params.id);

        this.data = item;
        // this.checkedValues.push(item.uuid);
        // this.getPayment(this.$route.params.id);

        // this.currentElement = item.uuid;
        this.getPo(this.$route.params.id);
        //  this.getTotal();

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item.barcode, disabled: true },
        ]);
        this.rerenderKey = Number(new Date());
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.showFilter = false;
      }
    },
    async getPo() {
      try {
        this.po_id = this.$route.params.id;
        const item1 = await GET_All_RESERVATION(this.po_id);
        this.items = item1.data;
        this.searchIteam = item1.data;
        // this.record = [
        //   {
        //     item
        //   }
        // ];

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item1.barcode, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },

  // getTotal(){
  //         this.product = this.pdetails

  // 			let sum = 0;
  //       console.log(this.pdetails)
  // 			this.product?.forEach((element) => {

  // 				sum = sum + Number(element.total);
  // 			});
  //       console.log(sum)
  // 			this.total= Number(sum);
  // 			//  this.getDiscount();
  // 			return this.total;

  //       },
  // computed: {
  //   purchaseOrderTab: {
  //     set(val) {
  //       let query = { ...this.$route.query };
  //       query.tab = val;
  //       query.t = new Date().getTime();
  //       if (val != this.purchaseOrderTab) {
  //         this.$router.replace({ query });
  //       }
  //     },
  //     get() {
  //       return this.$route.query.tab || "line-items";
  //     },
  //   },
  // },
  mounted() {
    // this.ttsupplier();

    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Detail", disabled: true },
    ]);
    // alert(this.$route.params.id)
    const { id } = this.$route.params;
    if (id) {
      this.itemId = toSafeInteger(id);
      this.currentElement = this.$route.params.id;
      this.getItem();

      // this.getPo();
    } else {
      this.goBack();
    }
  },

  components: {
    DeleteTemplate,
    ImageTemplate,
    ValueTemplate,
    // POLineItemsTab,
    // AttactmentTab,
    SalaryLogTab,
    // ChatNotes,
    TextInput,
    Dialog,
    StatusTab,
    // RecordPaymentDialog,
    //  PaymentsTab,
    AutoCompleteInput,
  },
  computed: {},
};
</script>
<style scoped>
.header-title {
  position: sticky;
  width: 85%;
  background-color: white;
  z-index: 99;
  top: 65px;
  border-top: 8px solid #f7941e !important;
}

.customtabs .v-tabs .v-tab:not(.v-tab--active) {
  background-color: #fff !important;
}

.customtabs.v-tabs .v-slide-group__wrapper .v-tab {
  font-size: 14px !important;
  font-weight: 600 !important;
}

/* .po-line-items tbody tr:nth-child(even) {
  background-color: #e3eff9 !important;
} */
.puchase-listing .puchase-listing-item:nth-child(odd) {
  background-color: #f2f6fa !important;
}

.puchase-listing .puchase-listing-item.active.cursor-pointer.v-list-item.theme--light {
  background-color: #9fcbf3 !important;
  /* opacity: 0.3!important; */
}
.position-relative {
  position: relative;
  display: flex;
  /* justify-content: flex-end;  */
  align-items: center; /* Vertically center the items */
}
.floating-btn-wrapper {
  position: fixed;
  bottom: 20px; /* Adjust this value as needed */
  right: 350px; /* Adjust this value as needed */
  z-index: 1000; /* Ensure it's above other content */
}
.call-btn-underlay {
  position: absolute !important;
  z-index: -1 !important;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  margin: auto !important;
  animation: cp-widget-button-underlay 1.5s infinite;
  border-radius: 100% !important;
}
@keyframes cp-widget-button-underlay {
  0% {
    opacity: 0.25;
    width: 0;
    height: 0;
  }
  40% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
    width: 120px;
    height: 120px;
  }
}
</style>
